import {
  de_default
} from "./chunk-HT3GQBXG.mjs";
import {
  en_default
} from "./chunk-6RSN2NTR.mjs";
import {
  fr_default
} from "./chunk-CW7E6PI4.mjs";

// src/frontend/index.ts
var frontend_default = { en: en_default, fr: fr_default, de: de_default };

export {
  frontend_default
};
